import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Collapse, List, ListItemText, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

// project import
import NavItem from './NavItem';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;


  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          // <NavGroup key={menuItem.id} item={menuItem} />

          <List component="div" disablePadding>
            <div>
              <NavItem key={menuItem.id} item={menuItem} level={1} />
            </div>
            {menuItem.children?.length > 0 &&
              // <Collapse in={open} timeout="auto" unmountOnExit>
              <div>
                {menuItem.children?.map((childItem) => {
                  return (
                    <NavItem key={childItem.id} item={childItem} level={2} />
                  )
                })}
              </div>
              // </Collapse>
            }
          </List>
        );
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>
          </Box>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {/* <ListItemText
        disableTypography
        primary={item.title}
        onClick={handleToggle}
        sx={{ cursor: 'pointer' }}
      /> */}
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
