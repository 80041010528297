import React, { useState, useEffect } from 'react';
import { postFeedback } from 'ApiService/apiService';
import MainCard from 'components/MainCard';

// material-ui
import {
    Avatar,
    CircularProgress,
    AvatarGroup,
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Stack,
    TextField,
    Typography,
    Checkbox,
    Radio,
    TextareaAutosize,
    Backdrop
} from '@mui/material';
import { styled } from '@mui/system';
// project import
import QuizOperations from 'routes/QuizList';

const blue = {
    100: '#DAECFF',
    200: '#b6daff',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    900: '#003A75',
};

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const StyledTextarea = styled(TextareaAutosize)(
    ({ theme }) => `]
    max-width : '100%';
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 5px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  
    &:hover {
      border-color: ${blue[400]};
    }
  
    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
    }
  
    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const QuizFeedbackComponent = ({ QuizType }) => {
    const [quizData, setQuizData] = useState(QuizType.quiz_data);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isQuizFinished, setIsQuizFinished] = useState(false);
    const [switchCount, setSwitchCount] = useState(0);
    const [quizSuggestions, setQuizSuggestions] = useState("");

    const handleSuggestionsChange = (event) => {
        setQuizSuggestions(event.target.value);
    };

    useEffect(() => {
        setSelectedOptions(prevOptions => {
            const newOptions = { ...prevOptions };
            QuizType.quiz_data.forEach((el, index) => {
                el.questions.forEach((em, j) => {
                    if (!newOptions[`${index};${j}`]) {
                        newOptions[`${index};${j}`] = null;
                    }
                })
            });
            return newOptions;
        });
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // Tab switched away
                setSwitchCount(switchCount + 1);
                console.log("handleVisibilityChange switchCount", switchCount);
            }
        };

        const handleWindowBlur = () => {
            // Window Blured (tab switched back)
            setSwitchCount(switchCount + 1);
            console.log("handleWindowBlur switchCount", switchCount);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, [switchCount]);

    const handleOptionSelect = (sectionQuestionIndex, optionIndex, sectionIndex, questionIndex) => {
        if (quizData[sectionIndex]?.questions?.[questionIndex].answer_index?.toString()?.split(",").length > 1) {
            setSelectedOptions(prevOptions => {
                const newOptions = { ...prevOptions };
                !newOptions[sectionQuestionIndex] && (newOptions[sectionQuestionIndex] = []);
                if (newOptions[sectionQuestionIndex].filter(el => el == optionIndex).length > 0) {
                    newOptions[sectionQuestionIndex] = newOptions[sectionQuestionIndex].filter(el => el != optionIndex);
                } else {
                    newOptions[sectionQuestionIndex].push(optionIndex);
                }
                return newOptions;
            });
        } else {
            setSelectedOptions(prevOptions => {
                const newOptions = { ...prevOptions };
                !newOptions[sectionQuestionIndex] && (newOptions[sectionQuestionIndex] = []);
                if (newOptions[sectionQuestionIndex].filter(el => el == optionIndex).length > 0) {
                    // newOptions[sectionQuestionIndex] = [];
                } else {
                    newOptions[sectionQuestionIndex] = [optionIndex];
                }
                return newOptions;
            });
        }
    };

    const handleQuizSubmit = () => {
        setIsSubmitting(true);
        // Verify if all questions are answered
        const areAllAnswersSelected = Object.values(selectedOptions).every(option => option !== null);
        if (!areAllAnswersSelected) {
            // alert('Please select an answer for each question before submitting.');
            // return;
        }

        // Perform quiz submission logic here
        // You can send the selected options to your backend for evaluation

        setIsQuizFinished(true);

        var quiz_data = quizData.map((section, sectionIndex) => {
            return {
                section_id: section._id,
                section_name: section.section_name,
                questions: section.questions.map((question, questionIndex) => {
                    return {
                        ...question,
                        user_answer_index: selectedOptions?.[`${sectionIndex};${questionIndex}`]?.map(e => e + 1)?.join(",") ?? ""
                    }
                })
            }
        })

        var feedbackData = {
            user_experience: selectedOptions?.["0;0"]?.length ?? false ? quizData?.[0]?.questions?.[0]?.mcqs_array?.[selectedOptions?.["0;0"]?.[0]] : "",
            difficult_section_name: selectedOptions?.["0;1"]?.length ?? false ? quizData?.[0]?.questions?.[1]?.mcqs_array.filter((ex, ind) => selectedOptions?.["0;1"].indexOf(ind) != -1) : [],
            user_pervious_experience_similar_test: selectedOptions?.["0;2"]?.length ?? false ? quizData?.[0]?.questions?.[2]?.mcqs_array?.[selectedOptions?.["0;2"]?.[0]] : "",
            suggestions: quizSuggestions,
        }

        var resultData = {
            quiz_data: quiz_data,
            quiz_id: QuizType._id,
            time_taken: 0,
            switch_tabs: 0,
            quiz_marks: 0,
            pass_marks: 0,
            quiz_name: "Assessment Window Feedback",
            quiz_api_path: "feedback",
        }
        // console.log("feedbackData", feedbackData);
        postFeedback({ ...feedbackData, resultData: resultData })
            .then(responseData => {
                setIsSubmitting(false);
                window.location.href = "/quiz";
            })
            .catch((error) => {
                setIsSubmitting(false);
                console.error('Error:', error);
            });
    };

    const areAllAnswersSelected = Object.values(selectedOptions).every(option => option !== null);

    if (!quizData || isSubmitting) {
        return (
            <Backdrop
                sx={{ color: '#fff', backgroundColor: "#ffffff70", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                open={true}
            >
                <CircularProgress />
            </Backdrop>
        ); // Show loading indicator while fetching quiz data
    } else {

        return (
            <div style={{
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ position: 'fixed', top: 0, zIndex: 9999, left: '60%', transform: 'translateX(-50%)', width: '100%', maxWidth: '45vw', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" sx={{ textAlign: 'right', right: 20, color: '#40a9ff', fontSize: '2.5rem' }}>Feedback</Typography>
                        </div>

                        {quizData.length > 0 && (
                            <div style={{ position: 'relative', zIndex: 1 }}>
                                {quizData.map((section, sectionIndex) => {
                                    return (
                                        <MainCard
                                            sx={{ boxShadow: 3, marginBottom: 2 }}
                                            isHover={false}
                                            cardMedia={(section?.image_url ?? false) ? true : false}
                                            cardMediaURL={section.image_url}
                                            isDescriptive={section?.question_description?.length > 0}
                                            card_description={section.question_description}
                                            description_sx={{ fontSize: '20px' }}
                                        >
                                            {section.questions.map((question, questionIndex) => (
                                                <div key={`${sectionIndex};${questionIndex}`}>
                                                    <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{question.question}</Typography>

                                                    <Stack>
                                                        {question.mcqs_array.map((option, optionIndex) => (
                                                            <Button
                                                                key={optionIndex}
                                                                fullWidth
                                                                sx={{
                                                                    display: 'inline-flex',
                                                                    justifyContent: 'flex-start',
                                                                    margin: 1,
                                                                    fontSize: '16px',
                                                                    textAlign: 'left',
                                                                    backgroundColor: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                        ? '#40a9ff'
                                                                        : 'transparent', // Change background color for selected option

                                                                    // color: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                    //     ? 'white'
                                                                    //     : 'black', // Change background color for selected option
                                                                }}
                                                                variant={selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0 ? 'contained' : 'text'}
                                                                onClick={() => handleOptionSelect(`${sectionIndex};${questionIndex}`, (optionIndex), sectionIndex, questionIndex)}
                                                            >
                                                                {quizData[sectionIndex]?.questions?.[questionIndex].answer_index?.toString()?.split(",").length > 1 ?
                                                                    (<Checkbox
                                                                        checked={selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0}
                                                                        inputProps={{ 'aria-label': 'checkbox' }}
                                                                        style={{
                                                                            marginRight: '4px',
                                                                            color: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                                ? 'white'
                                                                                : '',
                                                                        }}
                                                                    />) : (
                                                                        <Radio
                                                                            checked={selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0}
                                                                            inputProps={{ 'aria-label': 'radio' }}
                                                                            style={{
                                                                                marginRight: '4px',
                                                                                color: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                                    ? 'white'
                                                                                    : '',
                                                                            }}
                                                                        />
                                                                    )}
                                                                {option}
                                                            </Button>
                                                        ))}
                                                    </Stack>
                                                </div>
                                            ))}

                                            <div>
                                                <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>Suggections</Typography>
                                                <Stack sx={{ margin: "8px", padding: "8px" }}>
                                                    <StyledTextarea minRows={3} value={quizSuggestions} onChange={handleSuggestionsChange} fullWidth aria-label="empty textarea" placeholder="Suggestions" />
                                                </Stack>
                                            </div>
                                        </MainCard>)
                                })}
                            </div>
                        )}

                        <Button variant="contained" onClick={handleQuizSubmit} disabled={!areAllAnswersSelected} sx={{ fontSize: '18px', marginBottom: 2 }}>
                            {'Submit'}
                        </Button>
                    </Grid>
                </Grid>
            </div>
        );
    }
};

export default QuizFeedbackComponent;