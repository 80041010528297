import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import {
    Grid,
    Button,
    CircularProgress
} from '@mui/material';
import MainCard from 'components/MainCard';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { GetUserList, getUserbyId, createUser, updateUser, deleteUser } from '../../ApiService/apiService'
import UserUpdateForm from 'components/user/UserUpdateForm';
import { makeStyles } from '@mui/styles';
import DeleteConfirmationModal from 'components/user/DeleteConfirmationModal';
import SnackbarComponent from 'components/SnackbarComponent';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    mt: 2
};

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        maxWidth: '80%',
        width: 'auto',
        maxHeight: '80%',
        height: 'auto',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        outline: 'none',
        overflowY: 'auto',
    },
}));

export default function HRList() {
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    // Function to show the Snackbar
    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const classes = useStyles();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [rows, setRow] = useState([]);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        setIsAddingUser(false);
    };

    const handleDeleteClick = (params) => {
        return function () {
            // getUserByEmail(params.row.email);
            setDeleteUserId(params.row._id);
            setDeleteModalOpen(true);
        }
    };

    const handleCreateUser = (newUser) => {
        setIsLoading(true);
        createUser(newUser)
            .then((responseData) => {
                showSnackbar('HR Added Successfully!', 'success');
                getAllUsers();
                setIsLoading(false);
            })
            .catch((error) => {
                showSnackbar(error?.response?.data?.message ?? "", 'error');
                console.error('Error:', error);
                setIsLoading(false);
            });
    }

    const handleUpdateUser = (updatedUser) => {
        setIsLoading(true);
        updateUser(updatedUser)
            .then((responseData) => {
                showSnackbar('HR Updated Successfully!', 'success');
                getAllUsers();
                setIsLoading(false);
            })
            .catch((error) => {
                showSnackbar(error?.response?.data?.message ?? "", 'error');
                console.error('Error:', error);
                setIsLoading(false);
            });
    }

    const handleDeleteConfirm = () => {
        setIsLoading(true);
        deleteUser({ id: deleteUserId })
            .then((responseData) => {
                showSnackbar('HR Deleted Successfully!', 'success');
                getAllUsers();
                setIsLoading(false);
            })
            .catch((error) => {
                showSnackbar(error?.response?.data?.message ?? "", 'error');
                console.error('Error:', error);
                setIsLoading(false);
            });
        setDeleteUserId(null);
        setDeleteModalOpen(false);


    };

    const handleDeleteCancel = () => {
        // Close the modal
        setDeleteModalOpen(false);
    };

    const openModalHandler = (params) => {
        return function () {
            // getUserByEmail(params.row.email);
            setSelectedUser(params.row);
            handleOpenModal();
        }
    }

    const openAddUserModal = () => {
        setSelectedUser(null); // Clear the selected user
        setIsAddingUser(true); // Set the addition mode
        handleOpenModal();
    };


    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    ID
                </strong>
            ),
        },
        {
            field: 'uniqueId',
            headerName: 'Emp No',
            width: 90,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Emp No
                </strong>
            ),
        },
        {
            field: 'firstname',
            headerName: 'First name',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    First name
                </strong>
            ),
        },
        {
            field: 'lastname',
            headerName: 'Last name',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Last name
                </strong>
            ),
        },
        {
            field: 'email',
            headerName: 'E-Mail',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    E-Mail
                </strong>
            ),
        },
        {
            field: 'department',
            headerName: 'Department',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Department
                </strong>
            ),
        },
        {
            field: 'designation',
            headerName: 'Designation',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Designation
                </strong>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    <div>
                        <Button color="info" onClick={openModalHandler(params)}>
                            <EditOutlined />
                        </Button>
                        <Button color="error" onClick={handleDeleteClick(params)}>
                            <DeleteOutlined />
                        </Button>
                    </div>
                );
            },
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Action
                </strong>
            ),
        }
    ];

    function getAllUsers() {
        setIsLoading(true);
        GetUserList()
            .then((responseData) => {
                setRow(responseData.data.filter(el => el.user_role == "HR").map((el, index) => { return { ...el, id: index + 1 } }) ?? []);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }

    function getUserByEmail(email) {
        var params = {
            email: email
        }
        getUserbyId(params)
            .then((responseData) => {
                console.log(email, responseData);
                // setRow(responseData.data.filter(el => el.user_role == "HR").map((el, index) => { return { ...el, id: index + 1 } }) ?? []);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    const CustomToolbar = () => (
        <GridToolbarContainer>
            <Button
                variant="outlined"
                color="primary"
                onClick={openAddUserModal}
            >
                Add HR
            </Button>
            <GridToolbar />
        </GridToolbarContainer>
    );

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', backgroundColor: "#ffffff70", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                open={isLoading}
            >
                <CircularProgress />
            </Backdrop>
            <MainCard sx={{ mt: 2 }} content={false}>
                <DataGrid
                    sx={{
                        maxHeight: "80vh"
                    }}
                    rows={rows}
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    pagination
                    pageSize={10}
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                />
            </MainCard>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                className={classes.modal}
            >
                <Fade in={openModal}>
                    <MainCard sx={style} content={false} className={[classes.formContainer, classes.modalContent].join(' ')}>
                        <Grid rowSpacing={4.5} columnSpacing={2.75}>
                            {selectedUser && !isAddingUser && (
                                <UserUpdateForm
                                    user={selectedUser}
                                    onUpdateUser={(updatedUser) => {
                                        // Handle the user update logic here
                                        handleUpdateUser(updatedUser);
                                        // Call your API or update the user in your state as needed
                                    }}
                                    onCloseModal={handleCloseModal}
                                    userType='HR'
                                />
                            )}

                            {isAddingUser && (
                                <UserUpdateForm
                                    user={{}}
                                    onUpdateUser={(newUser) => {
                                        // Handle the new user addition logic here
                                        handleCreateUser(newUser);
                                        // Call your API or update the user in your state as needed
                                    }}
                                    onCloseModal={handleCloseModal}
                                    isAddingUser={true}
                                    userType='HR'
                                />
                            )}
                        </Grid>
                    </MainCard>
                </Fade>
            </Modal>

            <DeleteConfirmationModal
                open={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onDelete={handleDeleteConfirm}
            />

            <SnackbarComponent
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div >

    );
}