import { lazy } from 'react';

// project import
import { isAuthenticated } from '../pages/authentication/Auth'
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import { Navigate } from 'react-router-dom';
import HRDefault from 'pages/HR/index';
import CandidateDefault from 'pages/Candidate/index';
import DashboardDefault from 'pages/dashboard/index';
import QuizComponent from 'pages/quiz/QuizComponent';
import QuizOperations from './QuizList';
import QuizFeedbackDefault from 'pages/quiz-feedback/index';

// render - quiz
const QuizDefault = Loadable(lazy(() => import('pages/quiz')));

// ==============================|| MAIN ROUTING ||============================== //

var QuizRoutes = [];
// if (sessionStorage.getItem("userData") && JSON.parse(sessionStorage.getItem("userData"))?.user_role == "CANDIDATE") {
var QuizData = QuizOperations?.getList();
QuizRoutes = QuizData?.map(el => el.quiz_list?.map(ex => {
  return {
    path: ex.quiz_path,
    element: <QuizComponent QuizType={ex} />
  }
})).flat() ?? [];
// }

const SuperAdminPages = [
  {
    path: '/',
    element: <DashboardDefault />
  },
  {
    path: 'dashboard',
    element: <DashboardDefault />
  },
  {
    path: 'hr',
    element: <HRDefault />
  },
  {
    path: 'candidate',
    element: <CandidateDefault />
  },
];
const HRPages = [
  {
    path: '/',
    element: <DashboardDefault />
  },
  {
    path: 'dashboard',
    element: <DashboardDefault />
  },
  {
    path: 'candidate',
    element: <CandidateDefault />
  }
];
const CandidatePages = [
  {
    path: '/',
    element: <QuizDefault />
  },
  {
    path: 'quiz',
    element: <QuizDefault />
  },
  {
    path: 'quiz/feedback',
    element: <QuizFeedbackDefault />
  },
  ...QuizRoutes
];

const MainRoutes = {
  path: '/',
  children: [],
  element: isAuthenticated() ? (
    <MainLayout />
  ) : (
    <Navigate to="/login" replace />
  ),
};

switch (JSON.parse(sessionStorage.getItem("userData"))?.user_role) {
  case "SUPER_ADMIN":
    MainRoutes.children = SuperAdminPages;
    break;
  case "HR":
    MainRoutes.children = HRPages;
    break;
  case "CANDIDATE":
    MainRoutes.children = CandidatePages;
    break;

  default:
    break;
}

export default MainRoutes;
