import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const SnackbarComponent = ({ open, handleClose, message, severity }) => {
    const handleSnackbarClose = () => {
        handleClose();
    };

    return (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <MuiAlert onClose={handleSnackbarClose} severity={severity} sx={{ width: '100%', fontSize: '14px' }}>
                {message}
            </MuiAlert>
        </Snackbar>
    );
};

export default SnackbarComponent;
