import { useState, useEffect } from 'react';

// material-ui
import {
  Grid,
  Typography,
  Stack,
  Backdrop,
  CircularProgress,
  Box,
  Tooltip
} from '@mui/material';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import { GetUserList } from 'ApiService/apiService';
import { EyeOutlined, EyeInvisibleOutlined, CloseCircleOutlined, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Card } from '../../../node_modules/@mui/material/index';

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [counts, setCounts] = useState({
    Total: 0,
    AppearedTotal: 0,
    NotAppearedTotal: 0,
    TotalAptitude: 0,
    AppearedAptitude: 0,
    NotAppearedAptitude: 0,
    TotalCognitive: 0,
    AppearedCognitive: 0,
    NotAppearedCognitive: 0,
    TotalNumerical: 0,
    AppearedNumerical: 0,
    NotAppearedNumerical: 0,
    PassNumerical: 0,
    FailNumerical: 0,
    TotalAnalytics: 0,
    AppearedAnalytics: 0,
    NotAppearedAnalytics: 0,
    PassAnalytics: 0,
    FailAnalytics: 0,
    // TotalVerbal: 0,
    // AppearedVerbal: 0,
    // NotAppearedVerbal: 0,
    // PassVerbal: 0,
    // FailVerbal: 0,
    TotalGeneral: 0,
    AppearedGeneral: 0,
    NotAppearedGeneral: 0,
    PassGeneral: 0,
    FailGeneral: 0,
    TotalCritical: 0,
    AppearedCritical: 0,
    NotAppearedCritical: 0,
    PassCritical: 0,
    FailCritical: 0,
    TotalSjt: 0,
    AppearedSjt: 0,
    NotAppearedSjt: 0,
    PassSjt: 0,
    FailSjt: 0,
  });

  const quizShortMapping = {
    "aptitude": ['da', 'general', /* 'verbal' */],
    "cognitive": ['ct', 'sjt'],
    "total": ['da', 'general', /* 'verbal' ,*/ 'ct', 'sjt']
  }

  const quizMapping = {
    "da": ["Numerical Reasoning", "Data Analytics"],
    // "verbal": ["Verbal Reasoning"],
    "general": ["General Evaluation"],
    "ct": ["Critical Thinking"],
    "sjt": ["Situation Judgment Test"],
    "aptitude": ["Numerical Reasoning", "Data Analytics", /* "Verbal Reasoning", */ "General Evaluation"],
    "cognitive": ["Critical Thinking", "Situation Judgment Test"],
    "total": ["Numerical Reasoning", "Data Analytics", /* "Verbal Reasoning", */ "General Evaluation", "Critical Thinking", "Situation Judgment Test"]
  }


  const getAppearedCount = (TestType, users) => {
    return users.filter(el => (el?.assigned_quiz?.length && (quizShortMapping?.[TestType] || [TestType])?.some(x => el?.assigned_quiz.map(y => y.name).includes(x))) && (el?.user_result?.length && quizMapping?.[TestType]?.some(x => el?.user_result.map(y => y.quiz_name).includes(x))))?.length
  }

  const getResultCount = (resultType, TestType, users, isTotal = false) => {
    if (!isTotal) {
      switch (resultType) {
        case "pass":
          return users.filter(el => (el?.assigned_quiz?.length && (quizShortMapping?.[TestType] || [TestType])?.some(x => el?.assigned_quiz.map(y => y.name).includes(x))) && (el?.user_result?.length && quizMapping?.[TestType]?.some(x => el?.user_result.map(y => y.quiz_name).includes(x))) && (el.user_result.find(ez => quizMapping?.[TestType]?.includes(ez.quiz_name))?.marks >= el.user_result.find(ez => quizMapping?.[TestType]?.includes(ez.quiz_name))?.pass_marks))?.length
          break;
        case "fail":
          return users.filter(el => (el?.assigned_quiz?.length && (quizShortMapping?.[TestType] || [TestType])?.some(x => el?.assigned_quiz.map(y => y.name).includes(x))) && (el?.user_result?.length && quizMapping?.[TestType]?.some(x => el?.user_result.map(y => y.quiz_name).includes(x))) && (el.user_result.find(ez => quizMapping?.[TestType]?.includes(ez.quiz_name))?.marks < el.user_result.find(ez => quizMapping?.[TestType]?.includes(ez.quiz_name))?.pass_marks))?.length
          break;
        default:
          return 0;
          break;
      }
    } else {
      switch (resultType) {
        case "pass":
          return users.filter(el => el?.assigned_quiz?.length && el?.user_result?.length && (el?.assigned_quiz?.length == el?.user_result?.length) && (el?.user_result.every(x => x.marks >= x.pass_marks))).length
          break;
        case "fail":
          return users.filter(el => el?.assigned_quiz?.length && el?.user_result?.length && (el?.assigned_quiz?.length == el?.user_result?.length) && (el?.user_result.some(x => x.marks < x.pass_marks))).length
          break;
        default:
          return 0;
          break;
      }
    }
  }

  const setUserCounts = (users) => {
    setCounts(c => {
      c.Total = users.length;
      c.AppearedTotal = users.filter(el => el?.assigned_quiz?.length && el?.user_result?.length)?.length;
      c.NotAppearedTotal = users.filter(el => el?.assigned_quiz?.length && !el?.user_result?.length)?.length;
      c.TotalAptitude = users.filter(el => (el?.assigned_quiz?.length && quizShortMapping?.['aptitude'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      c.AppearedAptitude = getAppearedCount('aptitude', users);
      c.NotAppearedAptitude = c.TotalAptitude - c.AppearedAptitude;
      c.TotalCognitive = users.filter(el => (el?.assigned_quiz?.length && quizShortMapping?.['cognitive'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      c.AppearedCognitive = getAppearedCount('cognitive', users);
      c.NotAppearedCognitive = c.TotalCognitive - c.AppearedCognitive;
      c.TotalNumerical = users.filter(el => (el?.assigned_quiz?.length && ['da'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      c.AppearedNumerical = getAppearedCount('da', users);
      c.NotAppearedNumerical = c.TotalNumerical - c.AppearedNumerical;
      c.PassNumerical = getResultCount("pass", "da", users);
      c.FailNumerical = getResultCount("fail", "da", users);
      c.TotalAnalytics = users.filter(el => (el?.assigned_quiz?.length && ['da'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      c.AppearedAnalytics = getAppearedCount('da', users);
      c.NotAppearedAnalytics = c.TotalAnalytics - c.AppearedAnalytics;
      c.PassAnalytics = getResultCount("pass", "da", users);
      c.FailAnalytics = getResultCount("fail", "da", users);
      // c.TotalVerbal = users.filter(el => (el?.assigned_quiz?.length && ['verbal'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      // c.AppearedVerbal = getAppearedCount('verbal', users);
      // c.NotAppearedVerbal = c.TotalVerbal - c.AppearedVerbal;
      // c.PassVerbal = getResultCount("pass", "verbal", users);
      // c.FailVerbal = getResultCount("fail", "verbal", users);
      c.TotalGeneral = users.filter(el => (el?.assigned_quiz?.length && ['general'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      c.AppearedGeneral = getAppearedCount('general', users);
      c.NotAppearedGeneral = c.TotalGeneral - c.AppearedGeneral;
      c.PassGeneral = getResultCount("pass", "general", users);
      c.FailGeneral = getResultCount("fail", "general", users);
      c.TotalCritical = users.filter(el => (el?.assigned_quiz?.length && ['ct'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      c.AppearedCritical = getAppearedCount('ct', users);
      c.NotAppearedCritical = c.TotalCritical - c.AppearedCritical;
      c.PassCritical = getResultCount("pass", "ct", users);
      c.FailCritical = getResultCount("fail", "ct", users);
      c.TotalSjt = users.filter(el => (el?.assigned_quiz?.length && ['sjt'].some(x => el?.assigned_quiz.map(y => y.name).includes(x))))?.length;
      c.AppearedSjt = getAppearedCount('sjt', users);
      c.NotAppearedSjt = c.TotalSjt - c.AppearedSjt;
      c.PassSjt = getResultCount("pass", "sjt", users);
      c.FailSjt = getResultCount("fail", "sjt", users);
      c.TotalTotal = users.filter(el => el?.assigned_quiz?.length > 0)?.length;
      c.AppearedTotal = getAppearedCount('total', users);
      c.NotAppearedTotal = c.TotalTotal - c.AppearedTotal;
      c.PassTotal = getResultCount("pass", "total", users, true);
      c.FailTotal = getResultCount("fail", "total", users, true);
      return c;
    })
  }

  function getAllUsers() {
    setIsLoading(true);
    GetUserList()
      .then((responseData) => {
        let tempRows = responseData.data.filter(el => el.user_role == "CANDIDATE").map((el, index) => { return { ...el, id: index + 1 } }) ?? [];
        tempRows = tempRows.map(el => {
          if (el.user_result.length == 0) {
            return { ...el, result: 3 }
          } else if (el.user_result.length < el.assigned_quiz.length) {
            return { ...el, result: 2 }
          } else if (el.user_result.length > 0 && el.user_result.length >= el.assigned_quiz.length) {
            el.user_result = el.user_result.map(ez => checkResultStatusForAllTest(ez));
            if (el.user_result.find(ez => ez.result === 0)) {
              return { ...el, result: 0 }
            } else {
              return { ...el, result: 1 }
            }
          }
        })
        setUsers(tempRows);
        setUserCounts(tempRows);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
        setIsLoading(false);
      });
  }

  function checkResultStatusForAllTest(quizResult) {
    if (quizResult.marks >= quizResult.pass_marks) {
      quizResult.result = 1;
    } else {
      quizResult.result = 0;
    }
    return quizResult;
  }

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', backgroundColor: "#ffffff70", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>
      {!isLoading && <Grid container rowSpacing={4.5} columnSpacing={2.75}>
        {/* row 1 */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MainCard border={false} sx={{ boxShadow: 6 }}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography color='#a0a0a0' fontSize={18} variant="subtitle1"><EyeOutlined /> Total</Typography>
              <Typography color='#69c0ff' fontSize={24} variant="subtitle1">{`${counts?.AppearedTotal ?? 0} / ${counts?.Total ?? 0}`}</Typography>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MainCard border={false} sx={{ boxShadow: 6 }}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography color='#a0a0a0' fontSize={18} variant="subtitle1"><EyeInvisibleOutlined /> Total</Typography>
              <Typography color='#69c0ff' fontSize={24} variant="subtitle1">{`${counts?.NotAppearedTotal ?? 0} / ${counts?.Total ?? 0}`}</Typography>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MainCard border={false} sx={{ boxShadow: 6 }}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography color='#a0a0a0' fontSize={18} variant="subtitle1"><EyeInvisibleOutlined /> Aptitude</Typography>
              <Typography color='#69c0ff' fontSize={24} variant="subtitle1">{`${counts?.NotAppearedAptitude ?? 0} / ${counts?.Total ?? 0}`}</Typography>
            </Stack>
          </MainCard>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <MainCard border={false} sx={{ boxShadow: 6 }}>
            <Stack spacing={1} justifyContent="center" alignItems="center">
              <Typography color='#a0a0a0' fontSize={18} variant="subtitle1"><EyeInvisibleOutlined /> Cognitive Ability</Typography>
              <Typography color='#69c0ff' fontSize={24} variant="subtitle1">{`${counts?.NotAppearedCognitive ?? 0} / ${counts?.Total ?? 0}`}</Typography>
            </Stack>
          </MainCard>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>

        <MainCard
          border={false}
          sx={{
            width: "100%"
          }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box>
                    <Stack>
                      <Typography variant="h3" color="secondary">Module</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h3" color="primary">Candidates</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h3" color="rgb(19, 194, 194)">Appeared</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h3" color="rgb(250, 140, 22)">Not Appeared</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h3" color="rgb(82, 196, 26)">Pass</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h3" color="rgb(245, 34, 45)">Fail</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" color="secondary">Numerical</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="primary">{counts?.TotalNumerical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(19, 194, 194)">{counts?.AppearedNumerical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(250, 140, 22)">{counts?.NotAppearedNumerical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(82, 196, 26)">{counts?.PassNumerical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(245, 34, 45)">{counts?.FailNumerical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" color="secondary">Data Analytics</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="primary">{counts?.TotalAnalytics}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(19, 194, 194)">{counts?.AppearedAnalytics}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(250, 140, 22)">{counts?.NotAppearedAnalytics}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(82, 196, 26)">{counts?.PassAnalytics}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(245, 34, 45)">{counts?.FailAnalytics}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" color="secondary">General Evaluation</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="primary">{counts?.TotalGeneral}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(19, 194, 194)">{counts?.AppearedGeneral}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(250, 140, 22)">{counts?.NotAppearedGeneral}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(82, 196, 26)">{counts?.PassGeneral}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(245, 34, 45)">{counts?.FailGeneral}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
              {/* Disabling verbal reasoning */}
              {/* <TableRow>
                <TableCell>
                  <Typography variant="h5" color="secondary">Verbal Reasoning</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="primary">{counts?.TotalVerbal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(19, 194, 194)">{counts?.AppearedVerbal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(250, 140, 22)">{counts?.NotAppearedVerbal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(82, 196, 26)">{counts?.PassVerbal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(245, 34, 45)">{counts?.FailVerbal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>
                  <Typography variant="h5" color="secondary">Critical Thinking</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="primary">{counts?.TotalCritical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(19, 194, 194)">{counts?.AppearedCritical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(250, 140, 22)">{counts?.NotAppearedCritical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(82, 196, 26)">{counts?.PassCritical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(245, 34, 45)">{counts?.FailCritical}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h5" color="secondary">Situational Judgment Test</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="primary">{counts?.TotalSjt}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(19, 194, 194)">{counts?.AppearedSjt}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(250, 140, 22)">{counts?.NotAppearedSjt}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(82, 196, 26)">{counts?.PassSjt}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(245, 34, 45)">{counts?.FailSjt}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow sx={{
                backgroundColor: "rgb(217, 247, 190)"
              }}>
                <TableCell>
                  <Typography variant="h5" color="secondary">Total</Typography>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="primary">{counts?.TotalTotal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(19, 194, 194)">{counts?.AppearedTotal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(250, 140, 22)">{counts?.NotAppearedTotal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(82, 196, 26)">{counts?.PassTotal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box>
                    <Stack>
                      <Typography variant="h5" color="rgb(245, 34, 45)">{counts?.FailTotal}</Typography>
                    </Stack>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MainCard>



      </Grid>}
    </div>
  );
};

export default DashboardDefault;
