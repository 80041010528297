import React, { useState, useEffect, useContext } from 'react';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import {
    Grid,
    Button,
    Alert,
    Snackbar,
    Menu,
    MenuItem,
    IconButton,
    CircularProgress,
    Chip
} from '@mui/material';
import MainCard from 'components/MainCard';
import { DeleteOutlined, EditOutlined, EyeOutlined, MenuFoldOutlined, MailOutlined } from '@ant-design/icons';

import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { GetUserList, createUser, updateUser, deleteUser, SendQuizEmail } from '../../ApiService/apiService'
import UserUpdateForm from 'components/user/UserUpdateForm';
import { makeStyles } from '@mui/styles';
import DeleteConfirmationModal from 'components/user/DeleteConfirmationModal';
import ResultData from 'components/result/ResultData';
import SnackbarComponent from 'components/SnackbarComponent';
import DesignationList from 'components/user/DesignationList';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    mt: 2
};

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalContent: {
        maxWidth: '90%',
        minWidth: '60%',
        width: 'auto',
        maxHeight: '80%',
        height: 'auto',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        outline: 'none',
        overflowY: 'auto',
    },
}));

export default function CandidateList() {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (params) => {
        return function (event) {
            setSelectedUser(params.row);
            setAnchorEl(event.currentTarget);
        }
    };
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };


    const [FilteredRows, setFilteredRows] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');

    // Function to show the Snackbar
    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const classes = useStyles();
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [isAddingUser, setIsAddingUser] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalResult, setOpenModalResult] = useState(false);
    const [rows, setRow] = useState([]);
    const [openNoResult, setOpenNoResult] = React.useState(false);
    const handleCloseNoResult = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenNoResult(false);
    };
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => {
        setOpenModal(false);
        setIsAddingUser(false);
        setSelectedUser(null);
    };
    const handleOpenModalResult = () => setOpenModalResult(true);
    const handleCloseModalResult = () => {
        setOpenModalResult(false);
        setSelectedUser(null);
    };

    const handleDeleteClick = () => {
        return function () {
            setDeleteUserId(selectedUser?._id);
            setDeleteModalOpen(true);
        }
    };

    const handleCreateUser = (newUser) => {
        setIsLoading(true);
        createUser(newUser)
            .then((responseData) => {
                showSnackbar('Candidate Added Successfully!', 'success');
                getAllUsers();
                setIsLoading(false);
            })
            .catch((error) => {
                showSnackbar(error?.response?.data?.message ?? "", 'error');
                console.error('Error:', error);
                setIsLoading(false);
            });
    }

    const handleUpdateUser = (updatedUser) => {
        setIsLoading(true);
        updateUser(updatedUser)
            .then((responseData) => {
                showSnackbar('Candidate Updated Successfully!', 'success');
                getAllUsers();
                setIsLoading(false);
            })
            .catch((error) => {
                showSnackbar(error?.response?.data?.message ?? "", 'error');
                console.error('Error:', error);
                setIsLoading(false);
            });
    }

    const handleDeleteConfirm = () => {
        setIsLoading(true);
        deleteUser({ id: deleteUserId })
            .then((responseData) => {
                showSnackbar('Candidate Deleted Successfully!', 'success');
                getAllUsers();
                setIsLoading(false);
            })
            .catch((error) => {
                showSnackbar(error?.response?.data?.message ?? "", 'error');
                console.error('Error:', error);
                setIsLoading(false);
            });
        setDeleteUserId(null);
        setDeleteModalOpen(false);


    };

    const handleDeleteCancel = () => {
        // Close the modal
        setDeleteModalOpen(false);
    };

    const openModalHandler = () => {
        return function () {
            handleOpenModal();
        }
    }

    const openModalHandlerResult = () => {
        return function () {
            if (selectedUser?.user_result?.length ?? false) {
                handleOpenModalResult();
            } else {
                setOpenNoResult(true);
            }
        }
    }

    const openAddUserModal = () => {
        setSelectedUser(null); // Clear the selected user
        setIsAddingUser(true); // Set the addition mode
        handleOpenModal();
    };

    const sendQuizListEmail = () => {
        return function () {
            if (selectedUser && sessionStorage.getItem("QuizList") && JSON.parse(sessionStorage.getItem("QuizList"))) {
                let tempQuizData = JSON.parse(sessionStorage.getItem("QuizList")).map(el => {
                    el.quiz_list = el.quiz_list.map(ex => {
                        if (ex.quiz_api_path == "da") {
                            var designationExam = DesignationList.find(ez => ez.Designation == selectedUser?.Designation);

                            if (designationExam) {
                                ex.quiz_name = designationExam.TestType;
                            }
                        }
                        return {
                            ...ex,
                            status: (selectedUser?.assigned_quiz.find(e => e.name == ex.quiz_api_path) && selectedUser?.user_result.find(el => el.quiz_id == ex._id)) ? 1 : ((selectedUser?.assigned_quiz.find(e => e.name == ex.quiz_api_path)) ? 0 : 1),
                            isAvailable: selectedUser?.assigned_quiz.find(e => e.name == ex.quiz_api_path) ? true : false
                        }
                    })

                    return el;
                })

                const QuizEmailData = {
                    email: selectedUser?.email,
                    quizData: tempQuizData
                }
                setIsLoading(true);
                SendQuizEmail(QuizEmailData)
                    .then((responseData) => {
                        showSnackbar(responseData.message, "success");
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        showSnackbar(error?.response?.data?.message ?? "", 'error');
                        setIsLoading(false);
                    });
            }
        }
    }


    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            width: 90,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    ID
                </strong>
            ),
        },
        {
            field: 'uniqueId',
            headerName: 'CV No',
            width: 90,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    CV No
                </strong>
            ),
        },
        {
            field: 'firstname',
            headerName: 'First name',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    First name
                </strong>
            ),
        },
        {
            field: 'lastname',
            headerName: 'Last name',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Last name
                </strong>
            ),
        },
        {
            field: 'email',
            headerName: 'E-Mail',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    E-Mail
                </strong>
            ),
        },
        {
            field: 'department',
            headerName: 'Department',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Department
                </strong>
            ),
        },
        {
            field: 'designation',
            headerName: 'Designation',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Designation
                </strong>
            ),
        },
        {
            field: 'job_sponsor',
            headerName: 'HR POC',
            width: 150,
            align: "center",
            headerAlign: "center",
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    HR POC
                </strong>
            ),
        },
        {
            field: 'result',
            headerName: 'Result',
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                if (params.row.result == 0) {
                    return (<Chip label="Fail" color="error" variant="outlined" />);
                } else if (params.row.result == 1) {
                    return (<Chip label="Pass" color="success" variant="outlined" />);
                } else if (params.row.result == 2) {
                    return (<Chip label="In Progress" color="warning" variant="outlined" />);
                } else if (params.row.result == 3) {
                    return (<Chip label="Pending" color="info" variant="outlined" />);
                } else {
                    return "";
                }
            },
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Result
                </strong>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 60,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                    // <div>

                    // </div>

                    <div>
                        <Button
                            id="basic-button"
                            aria-controls={openMenu ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            onClick={(handleClickMenu(params))}
                        >
                            <MenuFoldOutlined />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                            PaperProps={{
                                sx: {
                                    // border: "1px solid",
                                    borderRadius: 1,
                                    boxShadow: 1,
                                },
                            }}
                        >
                            <MenuItem onClick={handleCloseMenu}>
                                <IconButton color="info" onClick={openModalHandler()}>
                                    <EditOutlined />
                                </IconButton>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <IconButton color="error" onClick={handleDeleteClick()}>
                                    <DeleteOutlined />
                                </IconButton>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <IconButton color="warning" onClick={openModalHandlerResult()}>
                                    <EyeOutlined />
                                </IconButton>
                            </MenuItem>
                            <MenuItem onClick={handleCloseMenu}>
                                <IconButton color="info" onClick={sendQuizListEmail()}>
                                    <MailOutlined />
                                </IconButton>
                            </MenuItem>
                        </Menu>
                    </div>
                );
            },
            renderHeader: (params) => (
                <strong style={{ fontSize: 14 }}>
                    Action
                </strong>
            ),
        }
    ];

    function checkResultStatusForAllTest(quizResult) {
        if (quizResult.marks >= quizResult.pass_marks) {
            quizResult.result = 1;
        } else {
            quizResult.result = 0;
        }
        return quizResult;
    }

    function getAllUsers() {
        setIsLoading(true);
        GetUserList()
            .then((responseData) => {
                let tempRows = responseData.data.filter(el => el.user_role == "CANDIDATE").map((el, index) => { return { ...el, id: index + 1 } }) ?? [];
                tempRows = tempRows.map(el => {
                    if (el.user_result.length == 0) {
                        return { ...el, result: 3 }
                    } else if (el.user_result.length < el.assigned_quiz.length) {
                        return { ...el, result: 2 }
                    } else if (el.user_result.length > 0 && el.user_result.length >= el.assigned_quiz.length) {
                        el.user_result = el.user_result.map(ez => checkResultStatusForAllTest(ez));
                        if (el.user_result.find(ez => ez.result === 0)) {
                            return { ...el, result: 0 }
                        } else {
                            return { ...el, result: 1 }
                        }
                    }
                })
                setRow(tempRows);
                setFilteredRows(tempRows);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error:', error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    const handleFilterChange = (params) => {
        const filtered = rows.filter((row) => {
            return row?.[params.items[0].field]?.toString()?.toLowerCase().indexOf(params?.items[0]?.value?.toLowerCase()) != -1 ?? false
        });
        setFilteredRows(filtered);
    };

    const CustomDownloadButton = () => {
        const handleDownload = () => {
            var csv = [[
                "id",
                "_id",
                "firstname",
                "lastname",
                "email",
                "password",
                "department",
                "designation",
                "experience",
                "user_role",
                "number_of_logged_attempt",
                "assigned_quiz",
                "job_sponsor",
                "user_location",
                "user_result"
            ]];
            var rowCSV = FilteredRows.reduce((prev, cur) => {
                prev.push(csv[0].map(el => {
                    if (el == "assigned_quiz") {
                        return cur[el].join(":")
                    } else if (el == "user_result") {
                        return cur[el].reduce((prRes, crRes) => {
                            prRes.push(["quiz_name : " + crRes["quiz_name"], "marks : " + crRes["marks"], "time_taken : " + crRes["time_taken"], "switch_tabs : " + crRes["switch_tabs"]].join("\t"))
                            return prRes;
                        }, []).join("\t")
                    } else {
                        return cur[el];
                    }
                }))
                return prev;
            }, []);

            csv = [...csv, ...rowCSV];
            let csvContent = "data:text/csv;charset=utf-8,"
                + csv.map(e => e.join(",")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "Candidate_Result.csv");
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);

        };

        return (
            <Button
                variant="outlined"
                color="primary"
                onClick={handleDownload}
            >
                Download Result
            </Button>
        );
    };

    const CustomToolbar = () => (
        <GridToolbarContainer>
            <Button
                variant="outlined"
                color="primary"
                onClick={openAddUserModal}
            >
                Add Candidate
            </Button>
            <GridToolbar />
            <CustomDownloadButton />
        </GridToolbarContainer>
    );

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', backgroundColor: "#ffffff70", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                open={isLoading}
            >
                <CircularProgress />
            </Backdrop>
            <MainCard sx={{ mt: 2 }} content={false}>
                <DataGrid
                    sx={{
                        maxHeight: "80vh"
                    }}
                    rows={rows}
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                    pagination
                    pageSize={10}
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    onFilterModelChange={handleFilterChange}
                />
            </MainCard>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                className={classes.modal}
            >
                <Fade in={openModal}>
                    <MainCard sx={style} content={false} className={[classes.formContainer, classes.modalContent].join(' ')}>
                        <Grid rowSpacing={4.5} columnSpacing={2.75}>
                            {selectedUser && !isAddingUser && (
                                <UserUpdateForm
                                    user={selectedUser}
                                    onUpdateUser={(updatedUser) => {
                                        // Handle the user update logic here
                                        handleUpdateUser(updatedUser);
                                        // Call your API or update the user in your state as needed
                                    }}
                                    onCloseModal={handleCloseModal}
                                    userType='Candidate'
                                />
                            )}

                            {isAddingUser && (
                                <UserUpdateForm
                                    user={{}}
                                    onUpdateUser={(newUser) => {
                                        // Handle the new user addition logic here
                                        handleCreateUser(newUser);
                                        // Call your API or update the user in your state as needed
                                    }}
                                    onCloseModal={handleCloseModal}
                                    isAddingUser={true}
                                    userType='Candidate'
                                />
                            )}
                        </Grid>
                    </MainCard>
                </Fade>
            </Modal>

            <DeleteConfirmationModal
                open={isDeleteModalOpen}
                onClose={handleDeleteCancel}
                onDelete={handleDeleteConfirm}
            />

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModalResult}
                onClose={handleCloseModalResult}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
                className={classes.modal}
            >
                <Fade in={openModalResult}>
                    <MainCard sx={style} content={false} className={[classes.formContainer, classes.modalContent].join(' ')}>
                        <Grid rowSpacing={4.5} columnSpacing={2.75}>
                            {selectedUser && (
                                <ResultData
                                    onCloseModal={handleCloseModalResult}
                                    resultList={selectedUser?.user_result ?? []}
                                    userData={selectedUser}
                                />
                            )}
                        </Grid>
                    </MainCard>
                </Fade>
            </Modal>

            <Snackbar open={openNoResult} autoHideDuration={3000} onClose={handleCloseNoResult}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}>
                <Alert severity="error" sx={{ width: '100%', fontSize: '14px' }}>
                    User have not completed any exam yet!
                </Alert>
            </Snackbar>

            <SnackbarComponent
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </div >

    );
}