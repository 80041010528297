import React, { useEffect, useState } from 'react';
import {
    Grid,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    Box,
    OutlinedInput,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { Refresh } from '@mui/icons-material/index';
import DesignationList from './DesignationList';
import DepartmentList from './DepartmentList';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const quizListProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const bandList = ["8A", "8M", "7A", "7M", "6", "5"];

const quizList = sessionStorage.getItem("QuizList") ? JSON.parse(sessionStorage.getItem("QuizList")).map(el => el.quiz_list).flat().map(el => ({ name: el.quiz_name, id: el.quiz_api_path })) ?? [] : [];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
    header: {
        padding: theme.spacing(1),
    },
    title: {
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#333',
    },
}));


const UserUpdateForm = ({ user, onUpdateUser, onCloseModal, isAddingUser, userType }) => {
    const theme = useTheme();
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {

    }, [])

    const validateForm = (name, value) => {
        let errors = {};

        if (name) {// Validate first name
            if (name == 'firstname' && !value) {
                errors.firstname = 'First name is required!';
            }

            // Validate last name
            if (name == 'lastname' && !value) {
                errors.lastname = 'Last name is required!';
            }

            if (name == 'uniqueId' && !value) {
                errors.uniqueId = 'Unique ID is required!';
            } else if (name == 'uniqueId' && !/^\d+$/.test(value)) {
                errors.uniqueId = 'Invalid uniqueId!';
            }

            if (name == 'password' && !value) {
                errors.password = 'Password is required!';
            } else if (name == 'password' && !/.{8,}/.test(value)) {
                errors.password = 'Password must be at least 8 characters!';
            }

            if (name == 'department' && !value) {
                errors.department = 'Department is required!';
            }

            if (name == 'designation' && !value) {
                errors.designation = 'Designation is required!';
            }

            // Validate email
            if (name == 'email' && !value) {
                errors.email = 'Email is required!';
            } else if (name == 'email' && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                errors.email = 'Invalid email format!';
            }

            // Validate user band
            if (name == 'user_band' && !value) {
                errors.user_band = 'Band is required!';
            }

            // Add more validation rules for other fields if needed

            setFormErrors(errors);
        }

        // Return true if the form is valid
        return Object.keys(formErrors).length === 0;
    };

    const validateFullForm = () => {
        let errors = {};

        if (!formData.firstname) {
            errors.firstname = 'First name is required!';
        }

        // Validate last name
        if (!formData.lastname) {
            errors.lastname = 'Last name is required!';
        }

        if (!formData.uniqueId) {
            errors.uniqueId = 'Unique ID is required!';
        } else if (!/^\d+$/.test(formData.uniqueId)) {
            errors.uniqueId = 'Invalid uniqueId!';
        }

        if (!formData.password) {
            errors.password = 'Password is required!';
        } else if (!/.{8,}/.test(formData.password)) {
            errors.password = 'Password must be at least 8 characters!';
        }

        if (!formData.department) {
            errors.department = 'Department is required!';
        }

        if (!formData.designation) {
            errors.designation = 'Designation is required!';
        }

        // Validate email
        if (!formData.email) {
            errors.email = 'Email is required!';
        } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
            errors.email = 'Invalid email format!';
        }

        // Validate user band
        if (!formData.user_band) {
            errors.user_band = 'Band is required!';
        }

        // Add more validation rules for other fields if needed

        setFormErrors(errors);

        // Return true if the form is valid
        return Object.keys(errors).length === 0;
    };

    const handleChangeQuiz = (event) => {
        console.log(event);
        const {
            target: { value },
        } = event;

        const updatedFormData = { ...formData };
        updatedFormData.assigned_quiz = typeof value === 'string' ? value.split(',') : value;
        setFormData(updatedFormData);
    };


    const classes = useStyles();

    const generateRandomPassword = () => {
        const length = 8;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let password = '';

        for (let i = 0; i < length; i++) {
            if (i > 0 && i % 4 === 0) {
                password += '-';
            }
            const randomIndex = Math.floor(Math.random() * characters.length);
            password += characters.charAt(randomIndex);
        }

        return password;
    }

    const [formData, setFormData] = useState({
        _id: user._id || '',
        firstname: user.firstname || '',
        lastname: user.lastname || '',
        uniqueId: user.uniqueId || '',
        email: user.email || '',
        password: user.password || generateRandomPassword(),
        department: user.department || (userType === "HR" ? 'Human Resource' : ''),
        designation: user.designation || '',
        experience: user.experience || '',
        user_band: user.user_band || '',
        user_role: user.user_role || userType.toUpperCase(),
        number_of_logged_attempt: user.number_of_logged_attempt || 0,
        job_sponsor: user.job_sponsor || (JSON.parse(sessionStorage.getItem("userData")).firstname + " " + JSON.parse(sessionStorage.getItem("userData")).lastname),
        user_location: user.user_location || '',
        assigned_quiz: user.assigned_quiz?.map(el => el.name) || (quizList.filter(el => el.id != 'sjt').map(el => el.id) ?? []),
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name == 'number_of_logged_attempt') {
            setFormData((prevData) => ({ ...prevData, [name]: Number(value) }));
            validateForm(name, Number(value))
        } else if (name == 'experience') {
            const restrictedValue = Math.min(Math.max(value, 0), 50);
            setFormData((prevData) => ({ ...prevData, [name]: restrictedValue }));
            validateForm(name, value)
        } else {
            setFormData((prevData) => ({ ...prevData, [name]: value }));
            validateForm(name, value)
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateFullForm()) {
            onUpdateUser({ ...user, ...formData });
            onCloseModal();
        }
    };

    return (
        <div>
            <div className={classes.header}>
                <h2 className={classes.title}>{isAddingUser ? 'Add ' + userType : 'Update ' + userType}</h2>
            </div>
            <form onSubmit={handleSubmit} noValidate>
                <div className={classes.formContainer}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="First Name"
                                name="firstname"
                                value={formData.firstname}
                                onChange={handleChange}
                                fullWidth
                                required
                                error={!!formErrors.firstname}
                                helperText={formErrors.firstname}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last Name"
                                name="lastname"
                                value={formData.lastname}
                                onChange={handleChange}
                                fullWidth
                                required
                                error={!!formErrors.lastname}
                                helperText={formErrors.lastname}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={userType === "HR" ? "Employee Id" : "Unique CV No"}
                                name="uniqueId"
                                value={formData.uniqueId}
                                onChange={handleChange}
                                fullWidth
                                required
                                error={!!formErrors.uniqueId}
                                helperText={formErrors.uniqueId}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                required
                                autoComplete="off"
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                                disabled={!isAddingUser}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                fullWidth
                                required
                                error={!!formErrors.password}
                                helperText={formErrors.password}
                                // type="password"
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <Button onClick={function () {
                                            const updatedFormData = { ...formData };
                                            updatedFormData.password = generateRandomPassword();
                                            setFormData(updatedFormData);
                                        }} variant="text" color="primary">
                                            <Refresh />
                                        </Button>
                                    ),
                                }}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                label="Department"
                                name="department"
                                value={formData.department}
                                onChange={handleChange}
                                fullWidth
                                required
                                error={!!formErrors.department}
                                helperText={formErrors.department}
                            />
                        </Grid> */}

                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Department</InputLabel>
                                <Select
                                    value={formData.department}
                                    label="Department"
                                    name="department"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={!!formErrors.department}
                                    helperText={formErrors.department}
                                >
                                    {DepartmentList.map(el => <MenuItem value={el.Department}>{el.Department}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            {/* <TextField
                                label="Designation"
                                name="designation"
                                value={formData.designation}
                                onChange={handleChange}
                                fullWidth
                            /> */}

                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                                <Select
                                    value={formData.designation}
                                    label="Designation"
                                    name="designation"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={!!formErrors.designation}
                                    helperText={formErrors.designation}
                                >
                                    {DesignationList.map(el => <MenuItem value={el.Designation}>{el.Designation}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        {userType != "HR" && <Grid item xs={12} sm={6}>
                            <TextField
                                type="number"
                                label="Experience"
                                name="experience"
                                value={formData.experience}
                                onChange={handleChange}
                                fullWidth
                                inputProps={{
                                    min: 0,
                                    max: 50,
                                }}
                            />
                        </Grid>
                        }
                        {userType != "HR" && <Grid item xs={12} sm={6}>
                            <FormControl sx={{ width: '100%' }}>
                                <InputLabel id="demo-multiple-chip-label">Assign Quiz</InputLabel>
                                <Select
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={formData.assigned_quiz}
                                    onChange={handleChangeQuiz}
                                    input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={quizListProps}
                                >
                                    {quizList.map((name) => (
                                        <MenuItem
                                            key={name.id}
                                            value={name.id}
                                            style={getStyles(name.id, formData.assigned_quiz, theme)}
                                        >
                                            {name.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">User Band</InputLabel>
                                <Select
                                    value={formData.user_band}
                                    label="User Band"
                                    name="user_band"
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={!!formErrors.user_band}
                                    helperText={formErrors.user_band}
                                >
                                    {bandList.map(el => <MenuItem value={el}>{el}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        {!isAddingUser && userType != "HR" && <Grid item xs={12} sm={6}>
                            <TextField
                                label="Number of Logged Attempt"
                                name="number_of_logged_attempt"
                                value={Number(formData.number_of_logged_attempt)}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        }
                        {userType != "HR" && <Grid item xs={12} sm={6}>
                            <TextField
                                label="User Location"
                                name="user_location"
                                value={formData.user_location}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <Button sx={{ float: 'right', margin: 1, border: 1 }} type="submit" variant="contained" color="primary" className={classes.submitButton}>
                                {isAddingUser ? 'Add ' + userType : 'Update ' + userType}
                            </Button>
                            <Button sx={{ float: 'right', margin: 1, border: 1 }} onClick={onCloseModal} color="primary" className={classes.submitButton}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </form>
        </div>
    );
};

export default UserUpdateForm;
