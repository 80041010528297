import React, { useState, useEffect } from 'react';
import { getQuiestionsListByExamName, postResult } from 'ApiService/apiService';
import MainCard from 'components/MainCard';
import { useDispatch, useSelector } from 'react-redux';
// material-ui
import {
    CircularProgress,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    Stack,
    Typography,
    Checkbox,
    Radio,
    Backdrop
} from '@mui/material';
import { openDrawer } from 'store/reducers/menu';
// project import
import CheckIcon from '@mui/icons-material/Check';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Sidebar from './QuizSideBar';
import QuizOperations from 'routes/QuizList';

const QuizComponent = ({ QuizType }) => {
    const [quizData, setQuizData] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(((sessionStorage.getItem("QuizAnswerData") ?? "{}") ? JSON.parse(sessionStorage.getItem("QuizAnswerData") ?? "{}") : {})[QuizType._id] ?? {});
    const [timer, setTimer] = useState(((sessionStorage.getItem("QuizRemainTime") ?? "{}") ? JSON.parse(sessionStorage.getItem("QuizRemainTime") ?? "{}") : {})[QuizType._id] ?? QuizType.quiz_time * 60);
    const [isQuizFinished, setIsQuizFinished] = useState(false);
    const [quizHeadingTitle, setQuizHeadingTitle] = useState('');
    const [switchCount, setSwitchCount] = useState(0);
    const dispatch = useDispatch();
    dispatch(openDrawer({ drawerOpen: false }));

    const setQuizAnswerDataInSession = (newOptions) => {
        if (sessionStorage.getItem("QuizAnswerData")) {
            if (JSON.parse(sessionStorage.getItem("QuizAnswerData"))[QuizType._id]) {
                let tempQuizAnsData = JSON.parse(sessionStorage.getItem("QuizAnswerData"));
                tempQuizAnsData[QuizType._id] = newOptions;
                sessionStorage.setItem("QuizAnswerData", JSON.stringify(tempQuizAnsData));
            } else {
                let tempQuizAnsData = JSON.parse(sessionStorage.getItem("QuizAnswerData"));
                tempQuizAnsData[QuizType._id] = newOptions;
                sessionStorage.setItem("QuizAnswerData", JSON.stringify(tempQuizAnsData));
            }
        } else {
            let tempQuizAnsData = {};
            tempQuizAnsData[QuizType._id] = newOptions;
            sessionStorage.setItem("QuizAnswerData", JSON.stringify(tempQuizAnsData));
        }
    }

    const setTimerInSession = (newTime) => {
        if (sessionStorage.getItem("QuizRemainTime")) {
            if (JSON.parse(sessionStorage.getItem("QuizRemainTime"))[QuizType._id]) {
                let tempQuizRemainTime = JSON.parse(sessionStorage.getItem("QuizRemainTime"));
                tempQuizRemainTime[QuizType._id] = newTime;
                sessionStorage.setItem("QuizRemainTime", JSON.stringify(tempQuizRemainTime));
            } else {
                let tempQuizRemainTime = JSON.parse(sessionStorage.getItem("QuizRemainTime"));
                tempQuizRemainTime[QuizType._id] = newTime;
                sessionStorage.setItem("QuizRemainTime", JSON.stringify(tempQuizRemainTime));
            }
        } else {
            let tempQuizRemainTime = {};
            tempQuizRemainTime[QuizType._id] = newTime;
            sessionStorage.setItem("QuizRemainTime", JSON.stringify(tempQuizRemainTime));
        }
    }

    useEffect(() => {
        dispatch(openDrawer({ drawerOpen: false }));
        var queryParams = {
            exam_name: QuizType.quiz_api_path,
            user_positions: JSON.parse(sessionStorage.getItem("userData")).designation
        }

        getQuiestionsListByExamName(queryParams)
            .then((responseData) => {
                setQuizData(responseData.data?.[0]?.exam_data ?? []);

                setSelectedOptions(prevOptions => {
                    const newOptions = { ...prevOptions };
                    responseData.data?.[0]?.exam_data.forEach((el, index) => {
                        el.questions.forEach((em, j) => {
                            if (!newOptions[`${index};${j}`]) {
                                newOptions[`${index};${j}`] = null;
                            }
                        })
                    })

                    setQuizAnswerDataInSession(newOptions);

                    return newOptions;
                });
            })
            .catch((error) => {
            });
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                // Tab switched away
                setSwitchCount(switchCount + 1);
                console.log("handleVisibilityChange switchCount", switchCount);
            }
        };

        const handleWindowBlur = () => {
            // Window Blured (tab switched back)
            setSwitchCount(switchCount + 1);
            console.log("handleWindowBlur switchCount", switchCount);
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('blur', handleWindowBlur);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('blur', handleWindowBlur);
        };
    }, [switchCount]);

    useEffect(() => {
        // Countdown timer
        setQuizHeadingTitle(QuizType.quiz_name);
        if (QuizType?.quiz_time != 0) {
            let intervalId;

            if (timer > 0 && !isQuizFinished) {
                intervalId = setInterval(() => {
                    setTimer(prevTimer => {
                        setTimerInSession(prevTimer - 1);
                        return prevTimer - 1
                    });
                }, 1000);
            } else if (timer === 0 && !isQuizFinished) {
                handleQuizSubmit();
            }
            return () => clearInterval(intervalId);
        }
    }, [timer, isQuizFinished]);

    const handleOptionSelect = (sectionQuestionIndex, optionIndex, sectionIndex, questionIndex) => {
        if (quizData[sectionIndex]?.questions?.[questionIndex].answer_index?.toString()?.split(",").length > 1) {
            setSelectedOptions(prevOptions => {
                const newOptions = { ...prevOptions };
                !newOptions[sectionQuestionIndex] && (newOptions[sectionQuestionIndex] = []);
                if (newOptions[sectionQuestionIndex].filter(el => el == optionIndex).length > 0) {
                    newOptions[sectionQuestionIndex] = newOptions[sectionQuestionIndex].filter(el => el != optionIndex);
                } else {
                    newOptions[sectionQuestionIndex].push(optionIndex);
                }

                setQuizAnswerDataInSession(newOptions);

                return newOptions;
            });
        } else {
            setSelectedOptions(prevOptions => {
                const newOptions = { ...prevOptions };
                !newOptions[sectionQuestionIndex] && (newOptions[sectionQuestionIndex] = []);
                if (newOptions[sectionQuestionIndex].filter(el => el == optionIndex).length > 0) {
                    // newOptions[sectionQuestionIndex] = [];
                } else {
                    newOptions[sectionQuestionIndex] = [optionIndex];
                }

                setQuizAnswerDataInSession(newOptions);

                return newOptions;
            });
        }
    };

    const handleQuizSubmit = () => {
        setIsSubmitting(true);
        // Verify if all questions are answered
        const areAllAnswersSelected = Object.values(selectedOptions).every(option => option !== null);
        if (!areAllAnswersSelected) {
            // alert('Please select an answer for each question before submitting.');
            // return;
        }

        // Perform quiz submission logic here
        // You can send the selected options to your backend for evaluation

        setIsQuizFinished(true);

        var quiz_data = quizData.map((section, sectionIndex) => {
            return {
                section_id: section._id,
                section_name: section.section_name,
                questions: section.questions.map((question, questionIndex) => {
                    return {
                        ...question,
                        user_answer_index: selectedOptions?.[`${sectionIndex};${questionIndex}`]?.map(e => e + 1)?.join(",") ?? ""
                    }
                })
            }
        })
        var time_taken = (QuizType?.quiz_time * 60) - timer;

        var resultData = {
            quiz_data: quiz_data,
            time_taken: time_taken,
            switch_tabs: switchCount,
            quiz_id: QuizType._id,
            quiz_marks: QuizType.quiz_marks,
            pass_marks: QuizType.pass_marks,
            quiz_name: QuizType.quiz_name,
            quiz_api_path: QuizType.quiz_api_path,
        }
        postResult(resultData)
            .then(responseData => {
                setIsSubmitting(false);
                QuizOperations.setQuizStatus(QuizType._id, 1);
                dispatch(openDrawer({ drawerOpen: true }));
                if (QuizOperations?.getList()?.find(el => el.quiz_list.find(ex => !ex.status))) {
                    window.location.href = "/quiz";
                } else {
                    window.location.href = "/quiz/feedback";
                }
            })
            .catch((error) => {
                setIsSubmitting(false);
                console.error('Error:', error);
            });
    };

    const areAllAnswersSelected = Object.values(selectedOptions).every(option => option !== null);

    if (!quizData || isSubmitting) {
        return (
            <Backdrop
                sx={{ color: '#fff', backgroundColor: "#ffffff70", zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                open={true}
            >
                <CircularProgress />
            </Backdrop>
        )
    } else {

        return (
            <div style={{
                WebkitTouchCallout: 'none',
                WebkitUserSelect: 'none',
                KhtmlUserSelect: 'none',
                MozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <div style={{ position: 'fixed', top: 0, zIndex: 9999, left: '60%', transform: 'translateX(-50%)', width: '100%', maxWidth: '45vw', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography variant="h5" sx={{ textAlign: 'right', right: 20, color: '#40a9ff', fontSize: '2.5rem' }}>{quizHeadingTitle}</Typography>
                            {QuizType?.quiz_time != 0 && <Typography variant="h5" sx={{ textAlign: 'right', right: 20, color: '#ff4d4f', fontSize: '2.5rem' }}><AccessTimeIcon fontSize='large' sx={{ paddingTop: '5px' }} /> {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}</Typography>}
                        </div>


                        <MainCard sx={{ boxShadow: 3, marginBottom: 2 }} isHover={false}>
                            <Stack spacing={1} justifyContent="center" alignItems="center">
                                {/* <Typography color={blue} fontSize={24} variant="subtitle1">{quizHeadingTitle}</Typography> */}
                                <List sx={{ textAlign: 'left' }}>
                                    {QuizType?.quiz_instruction?.split("\\n").map(inst => {
                                        return (<ListItem disablePadding>
                                            <CheckIcon color="primary" fontSize="small" sx={{ marginRight: '8px' }} />
                                            <ListItemText
                                                primaryTypographyProps={{ fontSize: '1.2rem' }}
                                                primary={inst} />
                                        </ListItem>);
                                    })}
                                </List>
                            </Stack>
                        </MainCard>

                        {quizData.length > 0 && (
                            <div style={{ position: 'relative', zIndex: 1 }}>
                                {quizData.map((section, sectionIndex) => {
                                    return (
                                        <MainCard
                                            sx={{ boxShadow: 3, marginBottom: 2 }}
                                            isHover={false}
                                            cardMedia={(section?.image_url ?? false) ? true : false}
                                            cardMediaURL={section.image_url}
                                            isDescriptive={section?.question_description?.length > 0}
                                            card_description={section.question_description}
                                            description_sx={{ fontSize: '20px', whiteSpace: 'pre-line' }}
                                        >
                                            {section.questions.map((question, questionIndex) => (
                                                <div key={`${sectionIndex};${questionIndex}`}>
                                                    <Typography variant="subtitle1" sx={{ fontSize: '18px' }}>{question.question}</Typography>

                                                    <Stack>
                                                        {question.mcqs_array.map((option, optionIndex) => (
                                                            <Button
                                                                key={optionIndex}
                                                                fullWidth
                                                                sx={{
                                                                    display: 'inline-flex',
                                                                    justifyContent: 'flex-start',
                                                                    margin: 1,
                                                                    fontSize: '16px',
                                                                    textAlign: 'left',
                                                                    backgroundColor: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                        ? '#40a9ff'
                                                                        : 'transparent', // Change background color for selected option

                                                                    // color: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                    //     ? 'white'
                                                                    //     : 'black', // Change background color for selected option
                                                                }}
                                                                variant={selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0 ? 'contained' : 'text'}
                                                                onClick={() => handleOptionSelect(`${sectionIndex};${questionIndex}`, (optionIndex), sectionIndex, questionIndex)}
                                                            >
                                                                {quizData[sectionIndex]?.questions?.[questionIndex].answer_index?.toString()?.split(",").length > 1 ?
                                                                    (<Checkbox
                                                                        checked={selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0}
                                                                        inputProps={{ 'aria-label': 'checkbox' }}
                                                                        style={{
                                                                            marginRight: '4px',
                                                                            color: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                                ? 'white'
                                                                                : '',
                                                                        }}
                                                                    />) : (
                                                                        <Radio
                                                                            checked={selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0}
                                                                            inputProps={{ 'aria-label': 'radio' }}
                                                                            style={{
                                                                                marginRight: '4px',
                                                                                color: selectedOptions[`${sectionIndex};${questionIndex}`]?.filter(el => el == optionIndex).length > 0
                                                                                    ? 'white'
                                                                                    : '',
                                                                            }}
                                                                        />
                                                                    )}
                                                                {option}
                                                            </Button>
                                                        ))}
                                                    </Stack>
                                                </div>
                                            ))}
                                        </MainCard>)
                                })}
                            </div>
                        )}

                        <Button variant="contained" onClick={handleQuizSubmit} disabled={!areAllAnswersSelected} sx={{ fontSize: '18px', marginBottom: 2 }}>
                            {'Submit Quiz'}
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        {/* Sidebar component */}
                        <Sidebar
                            quizData={quizData}
                            selectedOptions={selectedOptions}
                            handleOptionSelect={handleOptionSelect}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
};

export default QuizComponent;
