const DepartmentList = [
    {
        "Department": "APAC - Market"
    },
    {
        "Department": "Account Management"
    },
    {
        "Department": "Accounts & Finance"
    },
    {
        "Department": "Corporate"
    },
    {
        "Department": "Customer Delivery"
    },
    {
        "Department": "Customer Delivery - Automation"
    },
    {
        "Department": "Customer Delivery - BFSI/Lead Gen"
    },
    {
        "Department": "Customer Delivery - CRO"
    },
    {
        "Department": "Customer Delivery - Campaign Management"
    },
    {
        "Department": "Customer Delivery - Campaign Management - Optimization & Strategy"
    },
    {
        "Department": "Customer Delivery - Campaign Management - Trafficking & Reporting"
    },
    {
        "Department": "Customer Delivery - Creative & Design"
    },
    {
        "Department": "Customer Delivery - Data Analysis & Insights"
    },
    {
        "Department": "Customer Delivery - Data Collection"
    },
    {
        "Department": "Customer Delivery - Data Science"
    },
    {
        "Department": "Customer Delivery - OTT/Publisher"
    },
    {
        "Department": "Customer Delivery - Project Management"
    },
    {
        "Department": "Customer Delivery - Technology & Infrastructure"
    },
    {
        "Department": "Customer Development"
    },
    {
        "Department": "Customer Development - India"
    },
    {
        "Department": "Customer Development - Sales"
    },
    {
        "Department": "Human Resource"
    },
    {
        "Department": "IT & Admin"
    },
    {
        "Department": "Key Account Management"
    },
    {
        "Department": "Key Account Management (International)"
    },
    {
        "Department": "Maps"
    },
    {
        "Department": "Marketing"
    },
    {
        "Department": "Media"
    },
    {
        "Department": "NA - Market"
    },
    {
        "Department": "Opportunity & Emerging accounts"
    },
    {
        "Department": "Programme Management"
    },
    {
        "Department": "SME & Plugins"
    },
    {
        "Department": "Strategic Account Management"
    },
    {
        "Department": "Strategy & Solutions"
    },
    {
        "Department": "Tagmate"
    }
];

export default DepartmentList;