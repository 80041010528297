import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            padding={4}
            backgroundColor="#f5f5f5"
        >
            <Typography variant="h1" color="primary" gutterBottom>
                404
            </Typography>
            <Typography variant="h4" color="textSecondary" gutterBottom>
                Oops! Page not found.
            </Typography>
            <Typography variant="body1" color="textSecondary" align="center" gutterBottom>
                The page you are looking for might have been removed or doesn't exist.
            </Typography>
            <Button
                component={Link}
                to="/"
                variant="contained"
                color="primary"
                size="large"
                sx={{ marginTop: 2 }}
            >
                Go to Home
            </Button>
        </Box>
    );
};

export default NotFoundPage;
