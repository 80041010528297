import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar, GridToolbarContainer } from '@mui/x-data-grid';
import {
    Grid,
    Button,
    Stack,
    Card,
    Paper,
    CardContent,
    Typography,
    Chip
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MainCard from 'components/MainCard';

const useStyles = makeStyles((theme) => ({
    header: {
        top: 0,
        backgroundColor: theme.palette.background.paper,
        zIndex: theme.zIndex.appBar,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    formContainer: {
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
    card: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[6],
        borderRadius: theme.shape.borderRadius,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:hover': {
            transform: 'scale(1.02)',
            transition: 'transform 0.2s ease-in-out',
            cursor: 'pointer',
        },
    },
    title: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(1),
        fontSize: '1.4rem',
    },
    content: {
        color: theme.palette.text.primary,
        fontSize: '1rem',
        marginBottom: theme.spacing(1),
    },
}));


const ResultData = ({ onCloseModal, resultList, userData }) => {
    const classes = useStyles();

    const getChipForResult = (result) => {
        if (result == 0) {
            return (<Chip label="Fail" color="error" variant="outlined" />);
        } else if (result == 1) {
            return (<Chip label="Pass" color="success" variant="outlined" />);
        } else if (result == 2) {
            return (<Chip label="In Progress" color="warning" variant="outlined" />);
        } else if (result == 3) {
            return (<Chip label="Pending" color="info" variant="outlined" />);
        } else {
            return "";
        }
    }

    return (
        <div>
            <div className={classes.formContainer}>
                <div className={classes.header}>
                    <h1 className={classes.title}>{(userData?.firstname ?? "") + " " + (userData?.lastname ?? "")}</h1>
                </div>
                <Grid container spacing={2}>
                    {resultList.map((item) => (
                        <Grid item xs={12} sm={6} key={item.quiz_id}>
                            <MainCard sx={{ boxShadow: 6 }}>
                                <CardContent>
                                    <Typography variant="h6" className={classes.title}>
                                        {item.quiz_name} {getChipForResult(item.result)}
                                    </Typography>
                                    <Typography variant="body1" className={classes.content}>
                                        Marks: {item.marks}/{item.quiz_marks}
                                    </Typography>
                                    <Typography variant="body1" className={classes.content}>
                                        Time Taken: {`${Math.floor(item.time_taken / 60)}:${String(item.time_taken % 60).padStart(2, '0')}`}
                                    </Typography>
                                    <Typography variant="body1" className={classes.content}>
                                        Switch Tabs: {item.switch_tabs}
                                    </Typography>
                                    <Typography variant="body1" className={classes.content}>
                                        Assigned On: {userData.assigned_quiz.find(e => e.name == item.quiz_api_path)?.date ? new Date(userData.assigned_quiz.find(e => e.name == item.quiz_api_path).date).toLocaleString() : ""}
                                    </Typography>
                                    <Typography variant="body1" className={classes.content}>
                                        Completed On: {item.completed_time ? new Date(item.completed_time).toLocaleString() : ""}
                                    </Typography>
                                </CardContent>
                            </MainCard>
                        </Grid>
                    ))}

                    <Grid item xs={12}>
                        <Button sx={{ float: "right", margin: 1, border: 1 }} onClick={onCloseModal} color="primary" className={classes.submitButton}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default ResultData;