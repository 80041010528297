import React, { useEffect, useState } from 'react';

// material-ui
import {
    Grid
} from '@mui/material';
import QuizFeedbackComponent from './QuizFeedbackComponent';

const QuizFeedbackDefault = () => {
    const pastTestsTaken = JSON.parse(sessionStorage.getItem("userData")).user_result.length > 0 ? true : false;
    const [condition, setCondition] = useState(!pastTestsTaken);

    const handleConditionChange = (newValue) => {
        setCondition(newValue);
    };

    return (
        <Grid>
            <QuizFeedbackComponent QuizType={
                {
                    "_id": -1,
                    "name": "Feedback",
                    "status": 0,
                    "isAvailable": true,
                    "quiz_path": "/quiz/feedback",
                    "quiz_time": 80,
                    "quiz_data": [
                        {
                            "_id": "1234567890asdfghjkl",
                            "questions": [
                                {
                                    "question": "How was your overall experiance in this round ?",
                                    "mcqs_array": [
                                        "It was a smooth ride",
                                        "It was roller coaster",
                                        "It was brain teasing"
                                    ],
                                    "answer_index": ""
                                },
                                {
                                    "question": "Which section was most difficult to crack ?",
                                    "mcqs_array": [
                                        "Section 1 - Data Analysis Assessment / Numerical Reasoning Assessment",
                                        "Section 2 - Situation Judgment Test",
                                        "Section 3 - General Evaluation",
                                        // "Section 4 - Verbal Reasoning Test"
                                    ],
                                    "answer_index": "1,2,3,4"
                                },
                                {
                                    "question": "Did you have any previous experiance of attempting similar tests as a part of the interview process ?",
                                    "mcqs_array": [
                                        "Yes",
                                        "No"
                                    ],
                                    "answer_index": ""
                                }
                            ],
                            "instruction": "Feedback",
                            "question_type": "Feedback",
                            "mcqs_array": [],
                            "section_info": "Feedback",
                            "designation": "All",
                            "__v": 0
                        }
                    ]
                }
            } />
        </Grid>
    );
};

export default QuizFeedbackDefault;