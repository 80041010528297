import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const Sidebar = ({ quizData, selectedOptions }) => {
    var totalQuiestions = quizData.reduce((prev, cur) => prev + (cur?.questions?.length ?? 0), 0);
    var totalAnswered = quizData.reduce((prev, cur, i) => prev + (cur?.questions?.filter((el, j) => selectedOptions[`${i};${j}`]?.length > 0)?.length ?? 0), 0);
    return (
        <div>
            {/* Sidebar content goes here */}
            <Typography variant="h1" sx={{ textAlign: 'center', color: "#ff4d4f" }}>{totalAnswered} / {totalQuiestions}</Typography>
            {quizData?.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                    {section?.questions?.map((question, questionIndex) => (
                        <Card
                            key={`${sectionIndex}${questionIndex}`}
                            sx={{
                                marginBottom: 2,
                                backgroundColor: selectedOptions[`${sectionIndex};${questionIndex}`]?.length > 0 ? '#d9f7be80' : '#ffd8bf60',
                            }}
                        >
                            <CardContent>
                                <Typography variant="body1" color="inherit">
                                    {question.question.slice(0, 20)}...
                                </Typography>
                            </CardContent>
                        </Card>
                    ))}
                </div>
            ))}
        </div>
    );
};


export default Sidebar;
