// project import
import dashboard from './dashboard';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard] // dashboard, pages, utilities, support
};

export default menuItems;
