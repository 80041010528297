import axios from 'axios';

// const BASE_URL = 'https://pmo-quiz.onrender.com';
// const BASE_URL = 'http://localhost:5000';
const BASE_URL = 'https://quizportal-backend-api-5vkgg6cleq-el.a.run.app';

// Function to make a GET request
export function fetchItems() {
    return axios.get(`${BASE_URL}/items`)
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching items:', error);
            throw error;
        });
}

// Function to make a POST request
export function createItem(itemData) {
    return axios.post(`${BASE_URL}/items`, itemData)
        .then(response => response.data)
        .catch(error => {
            console.error('Error creating item:', error);
            throw error;
        });
}

// Export other API service functions as needed

export function Login(itemData) {
    return axios.post(`${BASE_URL}/users/login`, itemData)
        .then(response => response.data)
        .catch(error => {
            console.error('Error Logging in: ', error);
            throw error;
        });
}

export function GetUserList() {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.get(`${BASE_URL}/users/register`, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching users:', error);
            throw error;
        });
}

export function getUserbyId(queryParams) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.post(`${BASE_URL}/users/user`, queryParams, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching users:', error);
            throw error;
        });
}

export function createUser(itemData) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.post(`${BASE_URL}/users/register`, itemData, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching users:', error);
            throw error;
        });
}

export function updateUser(itemData) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        'user_role': "SUPER_ADMIN"
    };
    return axios.put(`${BASE_URL}/users/register`, itemData, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error updating users:', error);
            throw error;
        });
}

export function deleteUser(itemData) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.delete(`${BASE_URL}/users/register`, { data: itemData, headers: headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error updating users:', error);
            throw error;
        });
}

export function getQuiestionsListByExamName(queryParams) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        'user_role': "SUPER_ADMIN"
    };
    return axios.get(`${BASE_URL}/qnas/QnAs`, { params: queryParams, headers: headers })
        .then(response => response.data)
        .catch(error => {
            // console.error('Error fetching users:', error);
            throw error;
        });
}

export function GetQuizList() {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.get(`${BASE_URL}/quizlist/quiz`, { headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error fetching quiz list:', error);
            throw error;
        });
}

export function postResult(resultData) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.post(`${BASE_URL}/results/result`, resultData, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error posting result:', error);
            throw error;
        });
}

export function postFeedback(feedbackData) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.post(`${BASE_URL}/userfeedback/feedback`, feedbackData, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error posting feedback:', error);
            throw error;
        });
}

export function SendNewPassword(queryParams) {
    const headers = {
        'Content-Type': 'application/json'
    };
    return axios.get(`${BASE_URL}/users/forgotpassword`, { params: queryParams, headers: headers })
        .then(response => response.data)
        .catch(error => {
            // console.error('Error fetching user:', error);
            throw error;
        });
}

export function SendQuizEmail(QuizData) {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + sessionStorage.getItem('token'),
    };
    return axios.post(`${BASE_URL}/users/sendQuizEmail`, QuizData, { headers: headers })
        .then(response => response.data)
        .catch(error => {
            console.error('Error sending e-mail:', error);
            throw error;
        });
}