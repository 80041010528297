import { GetQuizList } from "ApiService/apiService";
import DesignationList from "components/user/DesignationList";

const QuizOperations = {
    getList: function () {
        try {
            if (sessionStorage.getItem("userData")) {
                if (sessionStorage.getItem("QuizList")) {
                    return JSON.parse(sessionStorage.getItem("QuizList"));
                } else {

                    GetQuizList()
                        .then((responseData) => {
                            responseData = responseData.data.map(el => {
                                el.quiz_list = el.quiz_list.map(ex => {
                                    if (ex.quiz_api_path === "da") {
                                        var designationExam = DesignationList.find(ez => ez.Designation == JSON.parse(sessionStorage.getItem("userData"))?.designation);

                                        if (designationExam) {
                                            ex.quiz_name = designationExam.TestType;
                                            ex.quiz_marks = designationExam.quiz_marks;
                                            ex.pass_marks = designationExam.pass_marks;
                                        }
                                    }
                                    if (ex.quiz_api_path === "general") {
                                        var user_band = JSON.parse(sessionStorage.getItem("userData")).user_band;

                                        if (user_band) {
                                            switch (user_band) {
                                                case "8A":
                                                case "8M":
                                                case "7A":
                                                    ex.pass_marks = 15;
                                                    break;
                                                default:
                                                    ex.pass_marks = 20;
                                                    break;
                                            }
                                        }
                                    }
                                    if (ex.quiz_api_path === "ct") {
                                        var user_band = JSON.parse(sessionStorage.getItem("userData")).user_band;

                                        if (user_band) {
                                            switch (user_band) {
                                                case "8A":
                                                case "8M":
                                                    ex.pass_marks = 40;
                                                    break;
                                                case "7A":
                                                case "7M":
                                                    ex.pass_marks = 43;
                                                    break;
                                                default:
                                                    ex.pass_marks = 48;
                                                    break;
                                            }
                                        }
                                    }
                                    return {
                                        ...ex,
                                        status: (JSON.parse(sessionStorage.getItem("userData")).assigned_quiz.find(e => e.name == ex.quiz_api_path) && JSON.parse(sessionStorage.getItem("userData")).user_result.find(el => el.quiz_id == ex._id)) ? 1 : ((JSON.parse(sessionStorage.getItem("userData")).assigned_quiz.find(e => e.name == ex.quiz_api_path) != -1) ? 0 : 1),
                                        isAvailable: JSON.parse(sessionStorage.getItem("userData")).assigned_quiz.find(e => e.name == ex.quiz_api_path)
                                    }
                                })

                                return el;
                            })
                            this.setList(responseData);
                            window.location.reload();
                            return responseData;
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                            return [];
                        });
                }
            }
        } catch (error) {
            return [];
        }
    },
    setList: function (newList) {
        try {
            sessionStorage.setItem("QuizList", JSON.stringify(newList));
            return true;
        } catch (error) {
            return false;
        }
    },
    setQuizStatus: function (quizId, newStatus) {
        try {
            let quiz = this?.getList() ?? [];
            quiz = quiz?.map(el => {
                el.quiz_list = el?.quiz_list?.map(em => {
                    if (em._id == quizId) {
                        em.status = newStatus;
                    }
                    return em;
                }) ?? [];
                return el;
            });
            var set = this.setList(quiz);
            if (set) { return true; } else { return false; }
        } catch (error) {
            return false;
        }
    },
    getSectionStatus: function (sectionId) {
        try {
            return (this?.getList()?.find(el => el._id == sectionId)?.quiz_list.find(el => !el.status) ? 0 : 1)
        } catch (error) {
            return undefined;
        }
    }
};

export default QuizOperations;