const DesignationList = [
    {
        "Designation": "Account Management Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Account Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Account Manager - Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Accounts Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Admin Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Admin Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Analytics Sales Expert",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Appscript Developer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Assistant Manager - Accounts & Finance",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Assistant Manager Accounts and Finance",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Assistant Manager - HR",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Associate - Account Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Associate - Customer Development",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Associate Product Designer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Associate Product Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Associate Technical Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Asst Marketing Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Asst. Marketing Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Associate Digital Analyst",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Automation Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Bigquery Developer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Business Finance",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Business Analyst",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "CRO Specialist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "CRO Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Campaign Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Campaign Management Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Campaign Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Campaign Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Campaign Manger",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Campiagn Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Cloud Developer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Cloud Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Cloud Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Consultant - Digital Analytics",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Content Manager & Strategist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Content Strategist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Copywriter",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Creative Designer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Customer Success Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Customer Success Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Customer Support and Engagement Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Data Analysis & Insight Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Data Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Data Collection Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Data Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Data Scientist",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Data Scientist Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Delivery Head",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Delivery Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Demand Generation Strategist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Design Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Devops Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Digital Analyst",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Digital Marketing Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Digital Marketing Specialist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Email Marketing Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Email Marketing Specialist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Executive - HR",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "GM - Accounts & Finance",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "GM Accounts & Finance",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "GM Legal",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "GM Taxation",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Google Analytics Expert",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Graphic Designer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Group Head HR",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "HR Business Partner",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "HR Generalist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Head - Account Management International",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head - Customer Development",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head - Customer Delivery",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head - Data Analysis & Insights",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head - India Delivery",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head - Opportunity & Emerging Accounts",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head HR",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head HRBP",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Head of Account Management",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head of Customer Development",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head of Media",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Head of Media",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "IT Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "IT Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Inside Sales Expert",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Inside Sales Executive",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Inside Sales Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Jr Accounts Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Jr Accounts Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Jr Quality Assurance Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Jr. Accounts Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Jr. Associate Legal",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Key Account Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Key Account Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Key Account Manager (International)",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Lead - Account Management",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Lead CRO Specialist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Lead Cloud Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Lead Web Developer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "LOB Lead - Cloud Sales",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "MIS Admin Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "MIS Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Manager - Customer Development",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Manager - Customer Development (Google Maps)",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Manager - HR",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Manager - Legal",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Manager - Marketing & Communication for Lead Gen",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Manager MIS",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Manager Talent Acquisition",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Management Trainee",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Marketing Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Marketing Head",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Marketing Manager-Lead Gen",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Marketing Strategist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Media Account Management Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Media Account Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Media Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Operations Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Partner - Plugin",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Planning Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Pre Sales Consultant",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Product & Business Head",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Product Designer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Product Management Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Product Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Product Marketing Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Project Management Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Project Manager",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Product Strategist & Solutions Architect",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Regional Sales Head - South Region",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Regional Head - Sales",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "SEO Expert",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Senior Manager - Customer Development",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Senior Solution Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Software Developer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Software Developer-Plugin",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Software QA/Tester",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Solution Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Solution Engineer Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Solutioning Expert",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Accounts Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Campaign Analyst",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Sr Cloud Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Creative Designer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Data Researcher & Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Executive - HR",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Product Marketing Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Quality Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Software Developer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Software QA Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Solution Engineer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Talent Acquistion Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Technical Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr. Content Writer & Strategist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr Consultant - Digital Analytics",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Sr. IT Consultant",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr. Manager Customer Development",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Sr. Marketing Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr. Technical Analyst (IOS)",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr.Associate Legal",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Sr.Technical Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Talent Acquistion Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Talent Acquisition Lead",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "Talent Manager",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Technical Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Technical Analyst",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Technical Consultant",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Technical Content Writer",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Technical Specialist",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Technical Trainee",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Technology & Infra Lead",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Travel & Admin Expert",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "Travel Executive",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    },
    {
        "Designation": "VP Customer Development",
        "TestType": "Data Analytics",
        "quiz_marks": 25,
        "pass_marks": 17
    },
    {
        "Designation": "VP Marketing",
        "TestType": "Numerical Reasoning",
        "quiz_marks": 95,
        "pass_marks": 59
    }
];

export default DesignationList;