import React, { useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

import HRList from './HRList';

const HRDefault = () => {
    const [condition, setCondition] = useState(true);

    const handleConditionChange = (newValue) => {
        setCondition(newValue);
    };

    return (
        <Grid>
            <HRList />
        </Grid>
    );
};

export default HRDefault;
