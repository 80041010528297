import { lazy } from 'react';

// project import
import { isAuthenticated } from '../pages/authentication/Auth'
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    }
  ],
  element: isAuthenticated() ? (() => {
    switch (JSON.parse(sessionStorage.getItem("userData"))?.user_role) {
      case "SUPER_ADMIN":
        return <Navigate to="/dashboard" replace />;
      case "HR":
        return <Navigate to="/dashboard" replace />;
      case "CANDIDATE":
        return <Navigate to="/quiz" replace />;
      default:
        return <Navigate to="/" replace />;
    }
  })() : (
    <MinimalLayout />
  )
};

export default LoginRoutes;
