import { Login } from 'ApiService/apiService';
// auth.js
var isLoggedIn = sessionStorage.getItem('isLoggedIn') ?? false; // Initial authentication status

export function login(username, password, handleClickLoginFail) {

  var LoginCredentials = {
    email: username,
    password: password
  };
  Login(LoginCredentials).then((responseData) => {
    console.log(responseData);
    sessionStorage.setItem('token', responseData.data.token);
    sessionStorage.setItem('userData', JSON.stringify(responseData.data));
    sessionStorage.setItem('isLoggedIn', true);
    isLoggedIn = true;
    window.location.reload();
    handleClickLoginFail(false);
  })
    .catch((error) => {
      console.log('Error:', error);
      handleClickLoginFail(error.response.data);
    });
}

export function logout() {

  sessionStorage.clear();
  isLoggedIn = false;
  window.location.href = "/";
}

export function isAuthenticated() {
  return isLoggedIn == 'true' ? true : false;
}
