import React, { useState } from 'react';

// material-ui
import { Grid } from '@mui/material';

import CandidateList from './CandidateList';

const CandidateDefault = () => {
    const [condition, setCondition] = useState(true);

    const handleConditionChange = (newValue) => {
        setCondition(newValue);
    };

    return (
        <Grid>
            <CandidateList />
        </Grid>
    );
};

export default CandidateDefault;
