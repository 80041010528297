// project import
import { useEffect, useState } from "react";
import ThemeRoutes from './routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => {
  useEffect(() => {
    if (sessionStorage.getItem("userData") && JSON.parse(sessionStorage.getItem("userData")).user_role == "CANDIDATE") {
      const handleKeyDown = (event) => {
        if (event.key === 'F12' || (event.ctrlKey && event.shiftKey && event.key === 'I') || (event.ctrlKey && event.shiftKey && event.key === 'C') || (event.ctrlKey && event.key === 'c')) {
          event.preventDefault(); // Prevent opening developer tools
          // Optionally, display a warning message
          console.log('Opening the developer tools is not allowed.');
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      const handleContextMenu = (event) => {
        event.preventDefault(); // Prevent default context menu
      };

      // Attach event listener to the entire document
      document.addEventListener('contextmenu', handleContextMenu);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, []);
  return (
    <div>
      <ThemeCustomization>
        <ScrollTop>
          <ThemeRoutes />
        </ScrollTop>
      </ThemeCustomization>
    </div>
  );
};

export default App;
