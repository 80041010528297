// assets
import {
  DashboardOutlined,
  BarChartOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  BarChartOutlined,
  UserSwitchOutlined,
  UsergroupAddOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const SuperAdminMenuItems = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/dashboard',
    icon: icons.DashboardOutlined,
    breadcrumbs: false
  },
  {
    id: 'hr',
    title: 'HR',
    type: 'item',
    url: '/hr',
    icon: icons.UserSwitchOutlined,
    breadcrumbs: false
  },
  {
    id: 'Candidate',
    title: 'Candidate',
    type: 'item',
    url: '/candidate',
    icon: icons.UsergroupAddOutlined,
    breadcrumbs: false
  }
];
const HRMenuItems = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/dashboard',
    icon: icons.DashboardOutlined,
    breadcrumbs: false
  },
  {
    id: 'Candidate',
    title: 'Candidate',
    type: 'item',
    url: '/candidate',
    icon: icons.UsergroupAddOutlined,
    breadcrumbs: false
  }
];
const CandidateMenuItems = [
  {
    id: 'quiz',
    title: 'Quiz',
    type: 'item',
    url: '/quiz',
    icon: icons.BarChartOutlined,
    breadcrumbs: false
  }
];

const dashboard = {
  id: 'group-dashboard',
  title: '',
  type: 'group',
  children: []
};

switch (JSON.parse(sessionStorage.getItem("userData"))?.user_role) {
  case "SUPER_ADMIN":
    dashboard.children = SuperAdminMenuItems;
    break;
  case "HR":
    dashboard.children = HRMenuItems;
    break;
  case "CANDIDATE":
    dashboard.children = CandidateMenuItems;
    break;

  default:
    break;
}

export default dashboard;